import React, { useRef, useEffect } from "react";

import { Link } from "react-router-dom";

import { connect } from "react-redux";

import "../css/profileNavbar.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineClose, AiTwotoneLock } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";

import profileDefualt from "../images/default.jpeg";

const url = process.env.REACT_APP_BACKEND_URL;

const ConsultantNavbar = (props) => {
  const navbarMenuRef = useRef(null);
  const userName = useRef(null);
  const password = useRef(null);
  const errorDetails = useRef(null);
  const progressBarRef = useRef(null);

  const clearErrorHandler = () => {
    errorDetails.current.innerHTML = "";
  };

  const clearErrorAuto = () => {
    setTimeout(function () {
      errorDetails.current.innerHTML = "";
    }, 5000);
  };

  const loginSubmit = async () => {
    const url = process.env.REACT_APP_BACKEND_URL;

    if (userName.current.value && password.current.value) {
      try {
        progressBarRef.current.style.display = "block";
        const response = await fetch(`${url}/api/users/userLogin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            login_user_id: userName.current.value,
            password: password.current.value,
          }),
        });
        const responseData = await response.json();

        if (!response.ok) {
          const error = responseData.message.split(":")[1].trim();
          if (error.localeCompare("password mismatch") === 0) {
            errorDetails.current.innerHTML = "Incorrect Password";
            errorDetails.current.style.color = "red";
            progressBarRef.current.style.display = "none";
          } else if (
            error.localeCompare("User not Exsited Try with other user_id") === 0
          ) {
            errorDetails.current.innerHTML = error;
            errorDetails.current.style.color = "red";
            progressBarRef.current.style.display = "none";
          } else {
            throw new Error(responseData.message || "something went wrong");
          }
        } else {
          errorDetails.current.innerHTML = "Loading..";
          errorDetails.current.style.color = "green";
          progressBarRef.current.style.display = "none";

          if (responseData.body.alreadyLogin) {
            alert("Alredy logged in somewere still you can use here too");
          }

          if (typeof Storage !== "undefined") {
            localStorage.setItem(
              "userDataSessionWay",
              JSON.stringify(responseData.body),
            );
            props.dispatch({ type: "CloseNavModel" });
            window.location.replace("/profile");
          } else {
            alert("please update your browser");
          }
        }
      } catch (err) {
        console.log(err);
        errorDetails.current.innerHTML =
          "Some thing went wrong try again later";
        errorDetails.current.style.color = "red";
        progressBarRef.current.style.display = "none";
        clearErrorAuto();
      }
    } else {
      errorDetails.current.innerHTML = "Please Enter Values";
      errorDetails.current.style.color = "red";
      clearErrorAuto();
    }
  };

  const navbarMenuHandler = () => {
    // navbarMenuRef.current.style.display = 'block'
    let isDisplay = navbarMenuRef.current.style.display;
    if (isDisplay === "block") {
      navbarMenuRef.current.style.display = "none";
    } else {
      navbarMenuRef.current.style.display = "block";
    }
  };

  const logoutHandler = async () => {
    try {
      const response = await fetch(`${url}/api/users/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.userData.token}`,
        },
        body: JSON.stringify({
          user_id: props.user.userData.loginUser._id,
        }),
      });
      const responseData = await response.json();
      if (!response.ok) {
        if (responseData.status === 401) {
          localStorage.removeItem("userDataSessionWay");
          window.location.replace("/");
        } else {
          throw new Error(responseData.message || "something went wrong");
        }
      } else {
        localStorage.removeItem("userDataSessionWay");
        window.location.replace("/");
        console.log(responseData.body);
      }
    } catch {}
  };

  return (
    <>
      <div className="profile_header_area d-md-block d-none d-sm-block">
        <div className="ml-4" style={{ float: "left" }}>
          <Link
            to="/"
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "16px",
              padding: "2px 10px 0px 10px",
              lineHeight: "37px",
            }}
          >
            SessionWay
          </Link>
        </div>
        {props.isLogin ? (
          <div className="push-right profileNavBarList">
            <BsThreeDotsVertical onClick={navbarMenuHandler} />
            <div className="dropdown-content" ref={navbarMenuRef}>
              <Link to="/" style={{ color: "black", textDecoration: "none" }}>
                <div className="cursor-pointer drop-down-navbar-menu ">
                  Home
                </div>
              </Link>

              <Link
                to="profile"
                style={{ color: "black", textDecoration: "none" }}
              >
                <div className="cursor-pointer drop-down-navbar-menu ">
                  Profile
                </div>
              </Link>

              <Link
                to="edit-profile"
                style={{ color: "black", textDecoration: "none" }}
              >
                <div className="cursor-pointer drop-down-navbar-menu ">
                  Edit-Profile
                </div>
              </Link>

              <div
                className="cursor-pointer drop-down-navbar-menu "
                onClick={logoutHandler}
              >
                Logout
              </div>
              <div className="cursor-pointer drop-down-navbar-menu ">
                Delete My Account
              </div>
            </div>
          </div>
        ) : (
          <div
            className="push-right profileNavBarList"
            style={{ color: "white", marginRight: "3%" }}
          >
            <span>
              <Link
                style={{ color: "white" }}
                to="#"
                onClick={() => {
                  console.log(props.dispatch);
                  props.dispatch({ type: "OpenNavModel" });
                }}
              >
                Login
              </Link>
            </span>
            <span> / </span>
            <span>
              <Link to="/register" style={{ color: "white" }}>
                Sign Up
              </Link>
            </span>
          </div>
        )}
      </div>
      <div
        className={`${
          props.isNavModalOpen ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <marquee
            className="progress-bar"
            direction="right"
            scrollamount="30"
            style={{ position: "absolute", display: "none" }}
            ref={progressBarRef}
          >
            <div className="load-bar">dsaddsadsadasdsadsa</div>
          </marquee>
          <h3 className="login-model-header">SignIn</h3>
          <button
            className="close-modal-btn"
            onClick={() => {
              props.dispatch({ type: "CloseNavModel" });
            }}
          >
            <AiOutlineClose></AiOutlineClose>
          </button>
          <div className="model-body model-font-color">
            <span ref={errorDetails}></span>
            <div className="form-group-navbar">
              <BsFillPersonFill />
              <input
                type="text"
                className="input-model-style input-login"
                placeholder="Enter Your user name"
                ref={userName}
                onChange={clearErrorHandler}
              />
            </div>
            <div className="form-group-navbar">
              <AiTwotoneLock />
              <input
                type="password"
                className="input-model-style input-login"
                placeholder="Enter your password"
                ref={password}
                onChange={clearErrorHandler}
              />
            </div>
            <span className="model-forgot-password">Forgot Password ?</span>
            <div className="btn-container">
              <button
                id="btnSubmit"
                type="submit"
                name="singlebutton"
                className="btn btn-primary fbt"
                onClick={loginSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const MapStateToProps = ({ NavbarLogin: { isNavModalOpen } }) => {
  return { isNavModalOpen };
};

export default connect(MapStateToProps)(ConsultantNavbar);
